import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { createUserWebinar } from "../../apiFunction/ApiFunctions";
import pain from "../../images/upcomingtraining/1.png";
import communication from "../../images/upcomingtraining/2.png";
import behaviour from "../../images/upcomingtraining/3.png";
import HeaderComponent from "../admin/shared/HeaderComponent";
import Header from "./Header";

// const webinars = [
//   {
//     date: "20th July 2023 - 10:00 AM",
//     title: "Pain",
//     presenter: "Dr. Emily Johnson",
//     description: "Pain in dementia patients",
//     image: pain,
//   },
//   {
//     date: "27th July 2023 - 2:00 PM",
//     title: "Effective Communication in Dementia",
//     presenter: "Dr. Sarah Thompson",
//     description: "Improving communication skills in dementia care.",
//     image: communication,
//   },
//   {
//     date: "20th August 2023 - 3:00 PM",
//     title: "Understanding Changes in Behaviour",
//     presenter: "Dr. Mark Roberts",
//     description: "Supporting the person who is distressed",
//     image: behaviour,
//   },
// ];

const WebinarCard = ({
  id,
  title,
  description,
  presenter,
  date_time,
  registered,
  navigate,
  state,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleRegister = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const user = JSON.parse(sessionStorage.getItem("userdetails"));
    // Prepare the form data
    const userWebinar = {
      user_id: user.id,
      webinar_id: id,
    };

    console.log(userWebinar);
    createUserWebinar(userWebinar)
      .then((data) => {
        console.log("userWebinar", data);
        setIsLoading(false);
        navigate("/success", {
          state: { title, description, presenter, date_time },
        });
      })
      .catch((error) => {
        console.error("Error creating user:", error);
      });
  };
  return (
    <div className="bg-white p-4 h-[545px] rounded-lg shadow-lg">
      <div className="h-[55%] mb-4">
        <img
          src={`https://66-dementia.b-cdn.net/test/webinars/images/${id}`}
          alt={title}
          className="w-full h-full  object-cover rounded-lg mb-4"
        />
      </div>
      <h2 className="text-lg  mb-2">
        {new Date(date_time).toLocaleDateString()} -{" "}
        {new Date(date_time).getHours() < 10
          ? "0" + new Date(date_time).getHours()
          : new Date(date_time).getHours()}
        :
        {new Date(date_time).getMinutes() < 10
          ? "0" + new Date(date_time).getMinutes()
          : new Date(date_time).getMinutes()}
        {/* {new Date(date_time).toLocaleTimeString()} */}
      </h2>
      <h3 className="text-xl font-bold mb-2">{title}</h3>
      <p className="text-gray-600 mb-2">Presented by: {presenter}</p>
      <p className="text-gray-700 mb-4">{description}</p>

      <button
        className={`${
          registered ? "bg-green-500" : "bg-blue-900"
        } text-white px-6 py-2 rounded-lg shadow-lg hover:${
          registered ? "bg-green-500" : "bg-blue-700"
        } mt-8`}
        onClick={(e) => {
          if (!registered) {
            handleRegister(e);
          }
        }}
      >
        {isLoading ? (
          <div role="status" className="ml-[]">
            <svg
              aria-hidden="true"
              className="h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        ) : registered ? (
          "Registered"
        ) : (
          "Register"
        )}
      </button>
    </div>
  );
};

const LiveTrainingWebinars = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  console.log(state);
  return (
    <div className="min-h-screen bg-white">
      <div className="container mx-auto px-4">
        <HeaderComponent />
        <div className="w-1/2 flex justify-between items-center">
          <h2 className="text-2xl font-semibold text-gray-700 mb-6">
            Live Training Webinars
          </h2>
          {/* <button
            className="bg-[#0089ad] text-white px-4 py-2 rounded-lg shadow-lg hover:bg-blue-700"
            onClick={() => {
              navigate("/recordedwebinars");
            }}
          >
            Recorded Sessions
          </button> */}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {state.webinars.map((webinar, index) => (
            <WebinarCard
              key={index}
              {...webinar}
              navigate={navigate}
              state={state}
            />
          ))}
        </div>
        <div className="mt-16 flex justify-center">
          <button
            className="bg-blue-900 text-white px-10 py-2 rounded-3xl shadow-lg hover:bg-blue-700"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default LiveTrainingWebinars;
