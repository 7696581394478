// src/components/CreateWebinarPage.js

import React, { useState } from "react";
import HeaderComponent from "./shared/HeaderComponent";
import { useLocation, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import { format, parse } from "date-fns";
import { createWebinar } from "../../apiFunction/ApiFunctions";

const CreateWebinarPage = () => {
  const location = useLocation();
  const { state } = location;

  const [id, setId] = useState(state ? state.id : null);
  const [title, setTitle] = useState(state ? state.title : "");
  const [description, setDescription] = useState(
    state ? state.description : ""
  );
  const [presenter, setPresenter] = useState(state ? state.presenter : "");
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState("10:00");
  const [duration, setDuration] = useState(state ? state.duration : 30);
  const [image, setImage] = useState(state ? state.image : null);
  const [loading, setLoading] = useState(false);
  const [liveUrl, setLiveUrl] = useState(state ? state.live_url : "");
  const navigate = useNavigate();

  // const handleThumbnailChange = (e) => {
  //   setThumbnail(URL.createObjectURL(e.target.files[0]));
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formattedDate = format(date, "MMMM d, yyyy");

    const parsedTime = parse(time, "HH:mm", new Date());
    const formattedTime = format(parsedTime, "hh:mm a");

    // setImage(toBase64(image));

    const formData = {
      id,
      title: title,
      description: description,
      presenter: presenter,
      date_time: `${formattedDate} ${formattedTime}`,
      duration_minutes: duration,
      live_url: liveUrl,
      image: image
        ? image.includes("https://")
          ? ""
          : image.split(",")[1]
        : null,
    };

    console.log(formData);

    // // Prepare the API payload
    // const apiPayload = {
    //   title: title,
    //   description: formData.description,
    //   presenter: formData.presenter,
    //   date_time: `${formData.date} ${formData.time}`, // Combining date and time
    //   duration_minutes: formData.duration, // Add duration if available
    //   recording_url: null, // Add recording URL if available
    //   archive_date: null
    // };

    createWebinar(formData).then((data) => {
      // console.log("data", data);
      navigate("/upcoming-training-webinars");
      setLoading(false);
    });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <HeaderComponent />

      <main className="w-full p-6 flex flex-col items-center">
        <form
          className="w-full max-w-8xl rounded-lg px-8 space-y-6 ml-4"
          onSubmit={handleSubmit}
        >
          <h2 className="text-2xl text-customBlue ml-[-1%]">
            Create Live Webinar
          </h2>
          <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Name:
            </label>
            <input
              type="text"
              className="border border-gray-300 rounded-lg p-3"
              placeholder="Name of Live Webinar"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>

          <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Description:
            </label>
            <textarea
              className="border border-gray-300 rounded-lg p-3"
              placeholder="Description of the Live Webinar"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>

          <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Presenter:
            </label>
            <input
              type="text"
              className="border border-gray-300 rounded-lg p-3"
              placeholder="Enter the Name of the Main Presenter on the Webinar"
              value={presenter}
              onChange={(e) => setPresenter(e.target.value)}
              required
            />
          </div>

          <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Select Date:
            </label>
            <div className="flex space-x-2">
              <DatePicker
                selected={date}
                onChange={(date) => setDate(date)}
                dateFormat="MMMM d, yyyy"
                className="border border-gray-300 rounded-lg p-3 w-full"
                required
              />
              <TimePicker
                onChange={setTime}
                value={time}
                className="border border-gray-300 rounded-lg p-3 w-1/3"
                required
              />
            </div>
          </div>

          <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Live URL:
            </label>
            <input
              type="text"
              className="border border-gray-300 rounded-lg p-3"
              placeholder="Enter the live URL link"
              value={liveUrl}
              onChange={(e) => setLiveUrl(e.target.value)}
              required
            />
          </div>

          <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Duration:
            </label>
            <input
              type="text"
              className="border border-gray-300 rounded-lg p-3"
              placeholder="Enter the Duration in minutes"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              required
            />
          </div>

          <div className="w-1/6 flex flex-col items-center">
            <div className="mb-4">
              {image ? (
                <img
                  src={image}
                  alt="Logo"
                  className="w-32 h-32 rounded-lg object-cover"
                />
              ) : (
                <div className="w-32 h-32 rounded-full bg-gray-200 flex items-center justify-center">
                  <center>
                    <span className="text-gray-500">Upload thumbnail</span>
                  </center>
                </div>
              )}
            </div>
            <label
              htmlFor="logo-upload"
              className="bg-customBlue text-white px-4 py-2 rounded-lg cursor-pointer"
            >
              Upload
            </label>
            <input
              id="logo-upload"
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              className="hidden"
            />
          </div>

          <div className="flex justify-center items-center">
            <button
              className="bg-gray-300 text-black py-3 px-6 rounded-lg font-medium w-32 h-12 mr-4"
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-900 text-white py-3 px-6 rounded-lg font-medium w-44"
              disabled={loading}
            >
              {loading ? (
                <center>
                  <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-white mr-2"></div>
                </center>
              ) : (
                <>{state ? "Update" : "Create"}</>
              )}
            </button>
          </div>
        </form>
      </main>
    </div>
  );
};

export default CreateWebinarPage;
