// src/components/CreateWebinarPage.js

import React, { useEffect, useState } from "react";
import HeaderComponent from "./shared/HeaderComponent";
import {
  createCourse,
  createModule,
  trainingCourses,
} from "../../apiFunction/ApiFunctions";
import { useLocation, useNavigate } from "react-router-dom";

const CreateModulePage = () => {
  const location = useLocation();
  const { state } = location;
  // console.log("state", state);s
  //   const [id, setId] = useState(state ? state.id : null);
  const [courseName, setCourseName] = useState(state ? state.course_name : "");
  const [title, setTitle] = useState(state ? state.title : "");
  const [description, setDescription] = useState(
    state ? state.description : ""
  );
  //   const [courseList, setCourseList] = useState([]);
  const [image, setImage] = useState(state ? state.image : null);
  //   const [url, setUrl] = useState(state ? state.url : "");
  // const [updatedFormData, setUpdatedFormData] = useState([]);
  //   // const [dateTime, setDateTime] = useState("");
  //   const [selectedCourse, setSelectedCourse] = useState(
  //     state ? state.selectedCourse : ""
  //   );
  const [loading, setLoading] = useState(false);
  // const [image, setImage] = useState(state ? state.image : "");

  const navigate = useNavigate();

  // const handleThumbnailChange = (e) => {
  //   setImage(URL.createObjectURL(e.target.files[0]));
  // };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // Prepare the form data
    const formData = {
      id: state ? state.id : null,
      course_id: state.course_id,
      title,
      description: description,
      image: image
        ? image.includes("https://")
          ? ""
          : image.split(",")[1]
        : null,
      total_ratings: 0,
      average_rating: 0,
    };

    createModule(formData).then(() => {
      navigate("/course-details", {
        state: {
          id: state.course_id,
          name: state.course_name
        },
      });
      setLoading(false);
    });

    // Check if any previous data is already there or not
    // const sessionTrainingVideo = JSON.parse(
    //   sessionStorage.getItem("trainingVideo")
    // );

    // let updatedFormData;
    // if (
    //   sessionTrainingVideo !== null &&
    //   !Array.isArray(sessionTrainingVideo) &&
    //   typeof sessionTrainingVideo === "object"
    // ) {
    //   updatedFormData = [formData, sessionTrainingVideo];
    // } else if (Array.isArray(sessionTrainingVideo)) {
    //   updatedFormData = [formData, ...sessionTrainingVideo];
    //   console.log(updatedFormData);
    // } else {
    //   updatedFormData = [formData];
    // }

    // // Save the form data to session storage
    // sessionStorage.setItem("trainingVideo", JSON.stringify(updatedFormData));

    // // Log the form data
    // console.log("Form Data as JSON:", JSON.stringify(updatedFormData, null, 2));

    // // Navigate to homescreen
    // navigate("/homescreen");
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <HeaderComponent />

      <main className="w-full p-6 flex flex-col items-center">
        <form
          className="w-full max-w-8xl rounded-lg px-8 space-y-6 ml-4"
          onSubmit={handleSubmit}
        >
          <h2 className="text-2xl text-customBlue ml-[-1%]">{state.id ? "Update" : "Create"} Module</h2>
          <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Course:
            </label>
            <input
              type="text"
              className="border border-gray-300 rounded-lg p-3"
              placeholder="Name of Training Module"
              value={state.course_name}
              required
              readOnly
            />
          </div>

          <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Title:
            </label>
            <input
              type="text"
              className="border border-gray-300 rounded-lg p-3"
              placeholder="Name of Training Module"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>

          <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Description:
            </label>
            <textarea
              className="border border-gray-300 rounded-lg p-3"
              placeholder="Description of the Live Webinar"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>

          {/* <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Training Courses:
            </label>
            <select
              className="w-full p-3 border border-gray-300 rounded-lg bg-white"
              value={selectedCourse}
              onChange={(e) => setSelectedCourse(e.target.value)}
            >
              <option value="" disabled selected>
                Choose your course
              </option>
              {courseList &&
                courseList.map((item) => (
                  <option value={item.name}>{item.name}</option>
                ))}
            </select>
          </div> */}

          {/* <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Video URL:
            </label>
            <input
              className="border border-gray-300 rounded-lg p-3"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </div> */}

          <div className="w-1/6 flex flex-col items-center">
            <div className="mb-4">
              {image ? (
                <img
                  src={image}
                  alt="Logo"
                  className="w-32 h-32 rounded-full object-cover"
                />
              ) : (
                <div className="w-32 h-32 rounded-full bg-gray-200 flex items-center justify-center">
                  <span className="text-gray-500">Upload image</span>
                </div>
              )}
            </div>
            <label
              htmlFor="logo-upload"
              className="bg-customBlue text-white px-4 py-2 rounded-lg cursor-pointer"
            >
              Upload Image
            </label>
            <input
              id="logo-upload"
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              className="hidden"
            />
          </div>

          <div className="flex justify-center items-center">
            <button
              type="submit"
              className="bg-blue-900 text-white py-3 px-6 rounded-lg font-medium w-40 h-12"
            >
              {loading ? (
                <center>
                  <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-white mr-2"></div>
                </center>
              ) : (
                <>{state.id ? "Update" : "Create"}</>
              )}
            </button>
          </div>
        </form>
      </main>
    </div>
  );
};

export default CreateModulePage;
