import React from "react";
import { useNavigate } from "react-router-dom";
import HeaderComponent from "../admin/shared/HeaderComponent";
import Header from "./Header";

const DementiaTrainingModules = () => {
  const navigate = useNavigate();
  const modules = [
    {
      title: "What is dementia?",
      description: [
        "Increase awareness about the different types of dementia",
        "The brain",
        "Bookcase model of memory",
        "Symptoms of dementia",
        "The factors that impact on the person’s ability during the day",
      ],
      plays: 256,
      stars: 5,
      image: require("../../images/trainingmodules/1.png"),
    },
    {
      title: "The 6 dimensions of dementia",
      description: [
        "Seeing the whole person, not just their cognitive ability:",
        "Factors that impact on daily functions",
        "Physical Health",
        "Life History",
        "Emotional Wellbeing",
        "Activity",
        "Safety",
        "Environment",
      ],
      plays: 187,
      stars: 5,
      image: require("../../images/trainingmodules/2.png"),
    },
    {
      title: "Changes in cognitive function",
      description: [
        "Orientation",
        "Attention",
        "Memory",
        "Language and Communication",
        "Visuospatial processing",
        "Insight",
        "Delusions",
        "Hallucinations",
      ],
      plays: 312,
      stars: 4,
      image: require("../../images/trainingmodules/3.png"),
    },
    {
      title: "Changes in behaviour",
      description: [
        "Normalising changes in behaviour",
        "Factors that impact on our behaviour",
        "What does a change in behaviour tell us",
        "Reframing behaviour",
        "Changes in behaviour as a sign of unmet need or distress",
        "Changing behaviour as a form of communication",
        "Responding to changes in behaviour",
        "Case examples",
      ],
      plays: 198,
      stars: 4,
      image: require("../../images/trainingmodules/4.png"),
    },
    {
      title: "Impact of physical health on behaviour",
      description: [
        "Pain",
        "Infection",
        "Delirium",
        "Dehydration",
        "Constipation",
        "Incontinence",
        "Medication",
        "Sensory Impairment",
        "Too hot or cold",
        "Hungry or thirsty",
        "Long term health condition",
      ],
      plays: 269,
      stars: 5,
      image: require("../../images/trainingmodules/5.png"),
    },
    {
      title: "The experience of loss for the person with dementia",
      description: ["The experience of loss for the person with dementia"],
      plays: 410,
      stars: 5,
      image: require("../../images/trainingmodules/6.png"),
    },
    {
      title: "The experience of loss for carers",
      description: ["The experience of loss for carers"],
      plays: 55,
      stars: 4,
      image: require("../../images/trainingmodules/7.png"),
    },
    {
      title: "Stress and behaviour",
      description: [
        "Normalising stress",
        "What causes us stress",
        "What does stress look like for us?",
        "What do we do when we're stressed?",
        "What would a person with dementia do when they are stressed",
        "Changes in behaviour as responses to stress",
      ],
      plays: 765,
      stars: 5,
      image: require("../../images/trainingmodules/8.png"),
    },
    {
      title: "Understanding the importance of perspective",
      description: [
        "Perspective and dementia",
        "Thoughts, feelings and behaviour",
      ],
      plays: 25,
      stars: 4,
      image: require("../../images/trainingmodules/9.png"),
    },
    {
      title: "Psychological wellbeing in dementia",
      description: [
        "Safety",
        "What causes us to feel unsafe",
        "What do we do when we feel unsafe",
        "Safety behaviours",
        "How to create safety in dementia",
        "Illustrations - safety",
      ],
      plays: 6,
      stars: 5,
      image: require("../../images/trainingmodules/10.png"),
    },
    {
      title: "Improving communication skills in dementia care",
      description: [
        "Impaired communication in dementia",
        "How can we support communication in dementia",
        "Communication tips",
        "Communication image",
      ],
      plays: 120,
      stars: 5,
      image: require("../../images/trainingmodules/11.png"),
    },
    {
      title: "Activity and social engagement",
      description: [
        "Why is meaningful interaction and occupation important",
        "How can we support meaningful interaction and engagement in dementia",
        "Activities appropriate to the stage of dementia",
        "Life story and interests",
      ],
      plays: 45,
      stars: 4,
      image: require("../../images/trainingmodules/12.png"),
    },
    {
      title: "Needs",
      description: [
        "What does the person with dementia need",
        "How to support the person living with dementia to meet their needs",
        "Needs of a person with dementia",
        "Health, happy, make our own decisions",
      ],
      plays: 24,
      stars: 4,
      image: require("../../images/trainingmodules/13.png"),
    },
    {
      title: "Tools to support understanding changes in behaviour",
      description: ["Tools to support understanding changes in behaviour"],
      plays: 2,
      stars: 4,
      image: require("../../images/trainingmodules/14.png"),
    },
    {
      title: "Pain Chart",
      description: [],
      plays: 0,
      stars: 0,
      image: require("../../images/trainingmodules/15.png"),
    },
    {
      title: "Behaviour Chart",
      description: [],
      plays: 0,
      stars: 0,
      image: require("../../images/trainingmodules/16.png"),
    },
  ];

  return (
    <div className="min-h-screen bg-white py-8 px-4 md:px-8 lg:px-16">
      <div className="container mx-auto">
        <HeaderComponent />
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-2xl font-bold">6D Dementia Training Modules</h1>
          <div className="flex items-center space-x-4">
            <select className="border rounded-md px-4 py-2">
              <option>Choose your Course</option>
              {/* Add more options here */}
            </select>
            {/* <button className="bg-blue-900 text-white px-4 py-2 rounded-md hover:bg-blue-700">
              Upload New Video
            </button> */}
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
          {modules.map((module, index) => (
            <div
              key={index}
              className="border rounded-lg overflow-hidden shadow-lg p-4"
            >
              <img
                src={module.image}
                alt={module.title}
                className="w-full object-cover"
              />
              <div className=" h-[450px] flex flex-col justify-between">
                <div>
                  <h3 className="text-lg font-semibold mb-2">{module.title}</h3>
                  <ul className="text-sm text-gray-700 mb-2">
                    {module.description.map((point, i) => (
                      <li key={i}>* {point}</li>
                    ))}
                  </ul>
                </div>
                <div className="">
                  <div className="flex items-center">
                    {Array.from({ length: module.stars }).map((_, i) => (
                      <span key={i} className="text-blue-900">
                        ★
                      </span>
                    ))}
                  </div>
                  <p className="text-sm text-gray-600">Plays: {module.plays}</p>
                  <button className="mt-4 bg-blue-900 text-white w-1/2 ml-[25%]  py-2 rounded-3xl hover:bg-blue-700"
                   onClick={() => {
                    navigate("/coursedetails");
                  }}
                  >
                    Details
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-8">
          <button
            className="bg-blue-900 text-white px-10 py-2 rounded-3xl hover:bg-blue-700 "
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default DementiaTrainingModules;
