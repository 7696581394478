import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "./components/admin/LoginPage";
import LoginForm from "./components/admin/LoginForm";
import "./index.css"; // Make sure to import the index.css where Tailwind directives are added
import Homescreen from "./components/admin/Homescreen";
import ActiveTrainingCourses from "./components/user/ActiveTrainingCourses";
import LiveTrainingWebinars from "./components/user/UpcomingTrainings";
import CompletedCourses from "./components/user/CompletedTrainings";
import SuccessPage from "./components/user/Success";
import RecordedWebinars from "./components/user/RecordedWebinars";
import TrainingModules from "./components/user/CourseDetails";
import MediaPlayer from "./components/user/MediaPlayer";
import SupportingMaterials from "./components/user/SupportingMaterials";
import DementiaTrainingModules from "./components/user/TrainingModules";
import CustomerListPage from "./components/admin/CustomerListPage";
import CreateWebinarPage from "./components/admin/CreateWebinarPage";
import CreateTrainingCoursePage from "./components/admin/CreateTrainingCoursePage";
import TrainingCoursesPage from "./components/admin/TrainingCoursesPage";
import SupportingMaterialsPage from "./components/admin/SupportingMaterialsPage";
import ExpiredSubscriptionCustomerPage from "./components/admin/ExpiredSubscriptionCustomerPage";
import OrganizationDashboardPage from "./components/admin/OrganizationDashboardPage";
import UserListPage from "./components/admin/UserListPage";
import CreateUserPage from "./components/admin/CreateUserPage";
import CreateOrganizationPage from "./components/admin/CreateOrganizationPage";
import UserProfilePage from "./components/admin/UserProfilePage";
import SubscriptionPage from "./components/admin/SubscriptionPage";
import UploadSupportingMaterialPage from "./components/admin/UploadSupportingMaterialPage";
import WebinarRegistrationListPage from "./components/admin/WebinarRegistrationListPage";
import UpcomingTrainingWebinarPage from "./components/admin/UpcomingTrainingWebinarPage";
import CourseDetailsPage from "./components/admin/CourseDetailsPage";
import Dashboard from "./components/user/Dashboard";
import PrivateAdminComponent from "./components/admin/PrivateAdminComponent";
import PrivateUserComponent from "./components/user/PrivateUserComponent";
import ManagerHomeScreen from "./components/care-home-admin/ManagerHomeScreen";
import ManagerDashboard from "./components/care-home-admin/ManagerDashboard";
import Teams from "./components/care-home-admin/Teams";
import CreateUser from "./components/care-home-admin/CreateUser";
import CreateTeam from "./components/care-home-admin/CreateTeam";
import EditOrganization from "./components/care-home-admin/EditOrganization";
import TeamProgress from "./components/care-home-admin/TeamProgress";
import UserCompletedTraining from "./components/care-home-admin/UserCompletedTraining";
import PrivateOrganizationalAdmin from "./components/care-home-admin/PrivateOrganizationalAdmin";
import UploadResearchPaper from "./components/care-home-admin/UploadResearchPaper";
import Upload6DHandbook from "./components/care-home-admin/Upload6DHandbooks";
import UploadPdfDocument from "./components/care-home-admin/UploadPdfDocument";
import RegisteredWebinars from "./components/user/RegisteredWebinars";
import Lessons from "./components/user/Lessons";
import UpcomingTrainingWebinars from "./components/care-home-admin/UpcomingTrainingWebinars";
import WebinarRegistrationList from "./components/care-home-admin/WebinarRegistrationList";
import OrganisationDashboard from "./components/care-home-admin/OrganisationDashboard";
import UserList from "./components/care-home-admin/UserList";
import UserProfile from "./components/care-home-admin/UserProfile";
import Subscriptions from "./components/care-home-admin/Subscriptions";
import UploadSupportingMaterials from "./components/care-home-admin/UploadSupportingMaterials";
import CreateModulePage from "./components/admin/CreateModulePage";
import CreateResearchPaperPage from "./components/admin/CreateResearchPaperPage";
import LessonsPage from "./components/admin/LessonsPage";
import CreateLessonPage from "./components/admin/CreateLessonPage";
import UploadVideoPage from "./components/admin/UploadVideoPage";
import CourseProgress from "./components/care-home-admin/CourseProgress";
import AdministratorProfile from "./components/care-home-admin/AdministratorProfile";
import AdminList from "./components/care-home-admin/AdminList";
import Create6HandbookPage from "./components/admin/Create6DHandbookPage";
import CreatePDFDocumentPage from "./components/admin/CreatePDFDocumentPage";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/mediaplayer" element={<MediaPlayer />} />
        <Route path="/supportingmaterials" element={<SupportingMaterials />} />

        {/* Organizational Admin Route */}
        <Route element={<PrivateOrganizationalAdmin />}>
          <Route path="/managerhomescreen" element={<ManagerHomeScreen />} />
          <Route path="/courseprogress" element={<CourseProgress />} />
          <Route
            path="/upcomingtrainingwebinars"
            element={<UpcomingTrainingWebinars />}
          />
          <Route
            path="/webinarregistrationlist"
            element={<WebinarRegistrationList />}
          />
          <Route path="/managerdashboard" element={<ManagerDashboard />} />
          <Route
            path="/organisationdashboard"
            element={<OrganisationDashboard />}
          />
          <Route path="/editorganization" element={<EditOrganization />} />
          <Route path="/adminlist" element={<AdminList />} />
          <Route path="/teamprogress" element={<TeamProgress />} />
          <Route path="/teams" element={<Teams />} />
          <Route path="/adminprofile" element={<AdministratorProfile />} />
          <Route path="/userprofile" element={<UserProfile />} />
          <Route path="/userlist" element={<UserList />} />
          <Route
            path="/usercompletedtraining"
            element={<UserCompletedTraining />}
          />
          <Route path="/createuser" element={<CreateUser />} />
          <Route path="/createteam" element={<CreateTeam />} />
          <Route path="/subscription" element={<Subscriptions />} />
          <Route
            path="/uploadsupportingmaterials"
            element={<UploadSupportingMaterials />}
          />
          <Route
            path="/upload-research-paper"
            element={<UploadResearchPaper />}
          />
          <Route path="/upload-handbooks" element={<Upload6DHandbook />} />
          <Route path="/upload-pdfdocuments" element={<UploadPdfDocument />} />
        </Route>

        {/* User Route */}
        <Route element={<PrivateUserComponent />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/activetrainings" element={<ActiveTrainingCourses />} />
          <Route path="/upcomingtrainings" element={<LiveTrainingWebinars />} />
          <Route path="/completedtrainings" element={<CompletedCourses />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/recordedwebinars" element={<RecordedWebinars />} />
          <Route path="/registeredwebinars" element={<RegisteredWebinars />} />
          <Route path="/coursedetails" element={<TrainingModules />} />
          <Route path="/lessons" element={<Lessons />} />

          <Route
            path="/trainingmodules"
            element={<DementiaTrainingModules />}
          />
        </Route>

        {/* Admin Route */}
        <Route element={<PrivateAdminComponent />}>
          <Route path="/homescreen" element={<Homescreen />} />
          <Route path="/customers" element={<CustomerListPage />} />
          <Route
            path="/expired-subscription-customers"
            element={<ExpiredSubscriptionCustomerPage />}
          />
          <Route
            path="/organization-dashboard"
            element={<OrganizationDashboardPage />}
          />
          <Route path="/create-webinar" element={<CreateWebinarPage />} />
          <Route
            path="/create-training-course"
            element={<CreateTrainingCoursePage />}
          />
          <Route path="/training-courses" element={<TrainingCoursesPage />} />

          <Route path="/create-module" element={<CreateModulePage />} />

          <Route path="/create-research-paper" element={<CreateResearchPaperPage />} />
          <Route path="/create-6d-handbook" element={<Create6HandbookPage />} />
          <Route path="/create-pdf-document" element={<CreatePDFDocumentPage />} />

          <Route path="/create-user" element={<CreateUserPage />} />
          <Route
            path="/upcoming-training-webinars"
            element={<UpcomingTrainingWebinarPage />}
          />
          <Route
            path="/webinar-registration-list"
            element={<WebinarRegistrationListPage />}
          />
          <Route
            path="/create-organization"
            element={<CreateOrganizationPage />}
          />
          <Route path="/course-details" element={<CourseDetailsPage />} />
          <Route path="/user-list" element={<UserListPage />} />
          <Route path="/user-profile" element={<UserProfilePage />} />
          <Route path="/subscriptions" element={<SubscriptionPage />} />
          <Route
            path="/supporting-materials"
            element={<SupportingMaterialsPage />}
          />
          <Route
            path="/upload-supporting-materials"
            element={<UploadSupportingMaterialPage />}
          />

          <Route path="/lesson-list" element={<LessonsPage />} />
          <Route path="/create-lesson" element={<CreateLessonPage />} />
          <Route path="/upload-video" element={<UploadVideoPage />} />
        </Route>

        {/* Other routes */}
      </Routes>
    </Router>
  );
};

export default App;
