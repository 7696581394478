// src/components/CreateWebinarPage.js

import React, { useEffect, useState } from "react";
import HeaderComponent from "./shared/HeaderComponent";
import {
  createPdfDocument,
  getOrganisations,
} from "../../apiFunction/ApiFunctions";
import { useLocation, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CreatePDFDocumentPage = () => {
  const location = useLocation();
  const { state } = location;

  const [id, setId] = useState(state ? state.id : null);
  const [title, setTitle] = useState(state ? state.title : "");
  const [description, setDescription] = useState(
    state ? state.description : ""
  );
  const [customers, setCustomers] = useState([]);
  const [image, setImage] = useState(state ? state.image : null);
  const [content, setContent] = useState(state ? state.content : null);
  const [selectedCustomer, setSelectedCustomer] = useState(
    state ? state.organisationId : null
  );
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getOrganisations().then((data) => {
      setCustomers(data);
    });
  }, []);

  const handlePdfDocument = async () => {
    // console.log(paper);
    const url = `https://66-dementia.b-cdn.net/test/pdfDocuments/contents/${
      state.id
    }.pdf?${new Date().getTime()}`;
    console.log("url", url);
    window.open(url);
    // window.open("https://dih-test.b-cdn.net/6D%20Dementia%20Training/Please%20C%20Me.pdf");

    // const response = await fetch(url);
    // console.log(response);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleContentUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setContent(reader.result);
        console.log(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const formData = {
        id,
        title,
        description,
        // organisation_id: selectedCustomer ? selectedCustomer : null,
        image:
          image && image.includes("https://")
            ? ""
            : image?.split(",")[1] || null,
        content: content.split(",")[1],
      };

      console.log("Form Data:", formData);
      await createPdfDocument(formData);
      navigate(-1);
    } catch (error) {
      console.error("Error submitting the form:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <HeaderComponent />

      <main className="w-full p-6 flex flex-col items-center">
        <form
          className="w-full max-w-4xl rounded-lg px-8 space-y-6"
          onSubmit={handleSubmit}
        >
          <h2 className="text-2xl text-customBlue font-bold">
          {state ? "Update" : "Create"} PDF Document
          </h2>

          <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Title:
            </label>
            <input
              type="text"
              className="border border-gray-300 rounded-lg p-3"
              placeholder="Title of the PDF Document"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>

          <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Description:
            </label>
            <textarea
              className="border border-gray-300 rounded-lg p-3"
              placeholder="Description of PDF Document"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>

          {/* <div className="flex flex-col">
            <label className="text-lg font-medium text-gray-700 mb-2">
              Organisation Name:
            </label>
            <select
              className="border border-gray-300 rounded-lg p-3"
              value={selectedCustomer}
              onChange={(e) => setSelectedCustomer(e.target.value)}
            >
              <option value="">Select Organisation</option>
              {customers.map((customer) => (
                <option key={customer.id} value={customer.id}>
                  {customer.name}
                </option>
              ))}
            </select>
          </div> */}

          <div className="flex space-x-8">
            <div className="flex flex-col items-center">
              <div className="mb-4">
                {image ? (
                  <img
                    src={image}
                    alt="Logo"
                    className="w-32 h-32 rounded-full object-cover"
                  />
                ) : (
                  <div className="w-32 h-32 rounded-full bg-gray-200 flex items-center justify-center">
                    <span className="text-gray-500">Upload Image</span>
                  </div>
                )}
              </div>
              <label
                htmlFor="logo-upload"
                className="bg-customBlue text-white px-4 py-2 rounded-lg cursor-pointer"
              >
                {state ? "Change" : "Upload"} Image
              </label>
              <input
                id="logo-upload"
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                className="hidden"
              />
            </div>

            <div className="flex flex-col items-center">
              <div className="mb-4">
                {content ? (
                  <div className="w-32 h-32 rounded-full bg-green-200 flex items-center justify-center">
                    {state ? (
                      <button onClick={() => handlePdfDocument()}>
                        {/* <button onClick={() => navigate("/homescreen")}> */}
                        View
                      </button>
                    ) : (
                      "File Uploaded"
                    )}
                  </div>
                ) : (
                  <div className="w-32 h-32 rounded-full bg-gray-200 flex items-center justify-center">
                    <span className="text-gray-500">Upload PDF</span>
                  </div>
                )}
              </div>
              <label
                htmlFor="pdf-upload"
                className="bg-customBlue text-white px-4 py-2 rounded-lg cursor-pointer"
              >
                {state ? "Change" : "Upload"} PDF
              </label>
              <input
                id="pdf-upload"
                type="file"
                accept=".pdf"
                onChange={handleContentUpload}
                className="hidden"
              />
            </div>
          </div>

          <div className="flex justify-center items-center">
            <button
              type="button"
              className="bg-gray-300 text-black py-3 px-6 rounded-lg font-medium w-32 h-12 mr-4"
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-900 text-white py-3 px-6 rounded-lg font-medium w-40 h-12"
            >
              {loading ? (
                <center>
                  <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-white"></div>
                </center>
              ) : state ? (
                "Update"
              ) : (
                "Create"
              )}
            </button>
          </div>
        </form>
      </main>
    </div>
  );
};

export default CreatePDFDocumentPage;
