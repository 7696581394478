// src/components/CustomerListPage.js

import React, { useEffect, useState } from "react";
import HeaderComponent from "./shared/HeaderComponent";
import { deleteWebinar, getWebinars } from "../../apiFunction/ApiFunctions";
import { useNavigate } from "react-router-dom";
import FooterComponent from "./shared/FooterComponent";
import { da } from "date-fns/locale/da";

const UpcomingTrainingWebinarPage = () => {
  const env = process.env.REACT_APP_ENV;

  const [webinars, setWebinars] = useState([]);
  const [webinarId, setWebinarId] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const auth = JSON.parse(sessionStorage.getItem("userdetails"));

  function dateFormat(date_time) {
    // Create a new Date object
    const dateObj = new Date(date_time);

    // Format the date to "26 August, 2024"
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = dateObj.toLocaleDateString("en-US", options);

    // Extract the time and format to AM/PM
    let hours = dateObj.getUTCHours();
    const minutes = dateObj.getUTCMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // Adjust to 12-hour format (handle midnight as 12)
    const formattedTime = `${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes} ${ampm}`;

    // console.log("Date:", formattedDate); // Output: 26 August, 2024
    // console.log("Time:", formattedTime); // Output: 10:00 PM

    return formattedDate + " " + formattedTime;
  }

  // Function to open the modal
  function openModal(id) {
    console.log("modal open");
    setWebinarId(id);
    setIsModalOpen(true);
  }

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  function confirmDelete(id) {
    deleteWebinar(id).then(() => {
      window.location.reload();
    });
    setIsModalOpen(false);
  }

  useEffect(() => {
    getWebinars()
      .then((data) => {
        console.log(data); // Access the responseData returned from the function
        setWebinars(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching webinars:", error);
      });
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <HeaderComponent />

      <main className="w-full p-6 flex flex-col items-center">
        <div className="w-full max-w-8xl rounded-lg px-8 space-y-6 ml-4">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl text-customBlue ml-[-1%]">
              Upcoming Training Webinars
            </h2>
            <button
              className="bg-yellow-500 text-white py-2 px-6 rounded-lg"
              onClick={() => navigate("/create-webinar")}
            >
              Create Live Webinar
            </button>
          </div>
          {loading ? (
            // Show loading icon while fetching data
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-16">
              {webinars.length > 0 &&
                webinars.map((webinar, index) => (
                  <div
                    key={index}
                    className="bg-white rounded-lg shadow-lg p-4 flex flex-col justify-between"
                  >
                    <div>
                      <img
                        // src={`https://66-dementia.b-cdn.net/${env}/webinars/images/${webinar.id}`}
                        src={`https://66-dementia.b-cdn.net/${env}/webinars/images/${
                          webinar.id
                        }?${new Date().getTime()}`}
                        alt={webinar.title}
                        className="w-full h-48 object-cover rounded-lg mb-4"
                      />
                      <div className="text-center mb-6">
                        <h3
                          className="text-xl text-customBlue mb-2 cursor-pointer"
                          onClick={() =>
                            navigate("/webinar-registration-list", {
                              state: {
                                id: webinar.id,
                                title: webinar.title,
                                // subtitle: webinar.subtitle,
                                presentedBy: webinar.presenter,
                                date_time: dateFormat(webinar.date_time),
                              },
                            })
                          }
                        >
                          {webinar.title}
                        </h3>
                        <p className="italic">{webinar.subtitle}</p>
                      </div>
                      <div className="space-y-2">
                        <p>
                          <b>Presented By:</b> {webinar.presenter}
                        </p>
                        <p>
                          <b>Time:</b> {dateFormat(webinar.date_time)}
                        </p>
                        <p>
                          <b>Duration (in minutes):</b>{" "}
                          {webinar.duration_minutes}
                        </p>
                        <p>
                          Staff Registered:{" "}
                          {webinar.staffRegistered
                            ? webinar.staffRegistered
                            : 0}
                        </p>
                      </div>
                      <div className="w-full mt-4 flex justify-around">
                        <button
                          className=" bg-yellow-500 text-white py-2 px-6 rounded-3xl"
                          onClick={() => {
                            navigate("/create-webinar", {
                              state: {
                                id: webinar.id,
                                title: webinar.title,
                                description: webinar.description,
                                presenter: webinar.presenter,
                                live_url: webinar.live_url,
                                date_time: webinar.date_time,
                                duration: webinar.duration_minutes,
                                image: `https://66-dementia.b-cdn.net/${env}/webinars/images/${
                                  webinar.id
                                }?${new Date().getTime()}`,
                              },
                            });
                          }}
                        >
                          Update
                        </button>
                        <button
                          className=" bg-red-600 text-white py-2 px-6 rounded-3xl"
                          onClick={() => openModal(webinar.id)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}
          {/* Modal */}
          {isModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
                <h2 className="text-xl font-bold mb-4">Confirm Delete</h2>
                <p className="text-gray-600 mb-6">
                  Are you sure you want to delete this item?
                </p>
                <div className="flex justify-end space-x-4">
                  <button
                    className="bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-600"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-red-600 text-white py-2 px-4 rounded-lg hover:bg-red-700"
                    onClick={() => confirmDelete(webinarId)}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </main>

      <FooterComponent path={"/homescreen"} />
    </div>
  );
};

export default UpcomingTrainingWebinarPage;
