import React from "react";
import Header from "./Header";
import image1 from "../../images/recordedwebinars/1.png";
import image2 from "../../images/recordedwebinars/2.png";
import image3 from "../../images/recordedwebinars/3.png";
import image4 from "../../images/recordedwebinars/4.png";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderComponent from "../admin/shared/HeaderComponent";

const RegisteredWebinars = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  console.log(state);
  // const webinars = [
  //   {
  //     title: "When someone wants to leave",
  //     presenter: "Dr. Emily Johnson",
  //     date: "June 15, 2022",
  //     duration: "1 hour 30 mins",
  //     image: image1,
  //   },
  //   {
  //     title: "How to effectively engage with carers",
  //     presenter: "Dr. James Smith",
  //     date: "July 5, 2022",
  //     duration: "2 hours",
  //     image: image2,
  //   },
  //   {
  //     title: "Unrecognised pain",
  //     presenter: "Dr. Sarah Adams",
  //     date: "July 20, 2022",
  //     duration: "1 hour 45 mins",
  //     image: image3,
  //   },
  //   {
  //     title: "Supporting with personal care",
  //     presenter: "Dr. Emily Johnson",
  //     date: "June 16, 2022",
  //     duration: "1 hour 20 mins",
  //     image: image4,
  //   },
  // ];

  return (
    <div className="min-h-screen flex flex-col items-center bg-white ">
      <div className="container mx-auto px-4">
        <HeaderComponent />
        <h2 className="text-3xl font-bold mb-2 ">Your Registered Webinars</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {state.registeredWebinars.map((webinar, index) => (
            <div
              key={webinar.id}
              className="bg-white p-4 h-[535px] rounded-lg shadow-lg"
            >
              <div className="h-[55%] mb-4">
                <img
                  src={`https://66-dementia.b-cdn.net/test/webinars/images/${webinar.id}`}
                  alt={webinar.title}
                  className="w-full h-full  object-cover rounded-lg mb-4"
                />
              </div>
              <h2 className="text-lg  mb-2">
                {new Date(webinar.date_time).toLocaleDateString()} -{" "}
                {new Date(webinar.date_time).getHours() < 10
                  ? "0" + new Date(webinar.date_time).getHours()
                  : new Date(webinar.date_time).getHours()}
                :
                {new Date(webinar.date_time).getMinutes() < 10
                  ? "0" + new Date(webinar.date_time).getMinutes()
                  : new Date(webinar.date_time).getMinutes()}
                {/* {new Date(webinar.date_time).getHours()}:
                {new Date(webinar.date_time).getMinutes()} */}
              </h2>
              <h3 className="text-xl font-bold mb-2">{webinar.title}</h3>
              <p className="text-gray-600 mb-2">
                Presented by: {webinar.presenter}
              </p>
              <p className="text-gray-700 mb-4">{webinar.description}</p>

              <button
                className={`bg-blue-900 text-white px-6 py-2 rounded-lg shadow-lg hover:bg-blue-700 mt-8 
                ${
                  new Date(webinar.date_time).getTime() <= new Date().getTime()
                    ? ""
                    : "cursor-not-allowed"
                } 
                `}
                onClick={() => {
                  if (
                    new Date(webinar.date_time).getTime() <=
                    new Date().getTime()
                  ) {
                    console.log("clicked");
                    window.open(webinar.live_url);
                  }
                }}
              >
                Join
              </button>
            </div>
            // <div key={index} className="bg-white p-4 rounded-lg shadow-lg">
            //   <img
            //     src={webinar.image}
            //     alt={webinar.title}
            //     className="w-full object-cover rounded-t-lg"
            //   />
            //   <div className="p-4">
            //     <h3 className="text-xl font-bold mb-2">{webinar.title}</h3>
            //     <p className="text-gray-700 mb-1">By: {webinar.presenter}</p>
            //     <p className="text-gray-700 mb-1">Date: {webinar.date}</p>
            //     <p className="text-gray-700 mb-4">
            //       Duration: {webinar.duration}
            //     </p>
            //     <div className="flex justify-center">
            //       <button className="bg-blue-900 text-white  px-8 py-2 rounded-lg shadow-lg hover:bg-blue-700"
            //        onClick={() => {
            //         navigate("/mediaplayer");
            //       }}
            //       >
            //         Join
            //       </button>
            //     </div>
            //   </div>
            // </div>
          ))}
        </div>
        <div className="mt-8 flex justify-center">
          <button
            className="bg-blue-900 text-white px-10 py-2 rounded-3xl shadow-lg hover:bg-blue-700"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegisteredWebinars;
