// src/components/CustomerListPage.js

import React, { useEffect, useState } from "react";
import HeaderComponent from "./shared/HeaderComponent";
import {
  createCourse,
  deleteCourse,
  getCourses,
  trainingCourses,
} from "../../apiFunction/ApiFunctions";
import { useNavigate } from "react-router-dom";
import FooterComponent from "./shared/FooterComponent";

const TrainingCoursesPage = () => {
  const [courses, setCourses] = useState([]);
  const [archiveCourse, setArchiveCourse] = useState(null);
  const navigate = useNavigate();
  const env = process.env.REACT_APP_ENV;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [courseId, setCourseId] = useState();
  const [id, setId] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  // useEffect(() => {
  //   const sessionTrainingVideo = JSON.parse(
  //     sessionStorage.getItem("trainingVideo")
  //   );
  //   console.log(sessionTrainingVideo);
  //   const trainingCoursesArr = trainingCourses();

  //   // Only add sessionTrainingVideo if it is valid
  //   if (sessionTrainingVideo) {
  //     console.log([...trainingCoursesArr, ...sessionTrainingVideo]);
  //     setCourses([...trainingCoursesArr, ...sessionTrainingVideo]);
  //   } else {
  //     setCourses(trainingCoursesArr);
  //   }
  // }, []);

  // Function to open the modal
  function openModal(id, course) {
    if (id) {
      setCourseId(id);
      console.log("modal open");
      setIsModalOpen(true);
    } else {
      setArchiveCourse(course);
      console.log("modal open");
      setIsModalOpen(true);
    }
    // console.log("modal open");
    // setIsModalOpen(true);
  }

  // Function to close the modal
  const closeModal = () => {
    setCourseId(null);
    setArchiveCourse(null);
    setIsModalOpen(false);
  };

  function confirmDelete(id) {
    setLoading(true);
    deleteCourse(id).then(() => {
      setCourses((prevCourses) => prevCourses.filter((c) => c.id !== id));
      navigate("/training-courses");
    });
    setLoading(false);
    setIsModalOpen(false);
  }

  function archive(course) {
    setIsModalOpen(false);

    setLoading(true);
    const date = new Date();

    const formData = {
      id: course.id,
      name: course.name,
      description: course.description,
      image: "",
      archive_date: date.toISOString().split("T")[0],
      total_ratings: 0,
      average_rating: 0,
    };

    createCourse(formData).then(() => {
      // Update the state to remove the archived course
      setCourses((prevCourses) =>
        prevCourses.filter((c) => c.id !== course.id)
      );

      setLoading(false);
      navigate("/training-courses");
    });
  }

  useEffect(() => {
    getCourses().then((data) => {
      setCourses(data);
      setLoading(false);
    });
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <HeaderComponent />
      <main className="w-full p-6 flex flex-col items-center">
        <div className="w-full max-w-8xl rounded-lg px-8 space-y-6 ml-4">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl text-customBlue ml-[-1%]">
              Training Courses
            </h2>
            <button
              className="bg-yellow-500 text-white py-2 px-6 rounded-lg"
              onClick={() => navigate("/create-training-course")}
            >
              Create Training Course
            </button>
          </div>
          {loading ? (
            // Show loading icon while fetching data
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
              {courses.map((course, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg shadow-lg p-4 flex flex-col justify-between"
                >
                  <div
                    className="cursor-pointer"
                    onClick={() =>
                      navigate("/course-details", {
                        state: {
                          id: course.id,
                          name: course.name,
                          description: course.description,
                        },
                      })
                    }
                  >
                    <img
                      src={`https://66-dementia.b-cdn.net/${env}/courses/images/${
                        course.id
                      }?${new Date().getTime()}`}
                      alt={course.name}
                      className="w-full h-48 object-cover rounded-lg mb-4"
                    />
                    <h3 className="text-xl font-semibold mb-2">
                      {course.name}
                    </h3>
                    <ul className="list-disc pl-5 mb-4 text-gray-700">
                      {course.description
                        .split(".")
                        .filter((item) => item.trim() !== "")
                        .map((item, i) => (
                          <li key={i}>{item}</li>
                        ))}
                    </ul>
                  </div>
                  <div className=" items-center mt-4">
                    {/* <div className="flex justify-between">
                      <div className="flex items-center text-yellow-500">
                        {[...Array(2)].map((_, i) => (
                          <svg
                            key={i}
                            className={`w-5 h-5 ${
                              i < 5 ? "text-yellow-500" : "text-gray-300"
                            }`}
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.049 2.927C9.469 1.777 10.53 1.777 10.951 2.927l1.286 3.968 4.168.325c1.199.093 1.719 1.56.83 2.378l-3.106 2.9.948 4.086c.27 1.157-.805 2.089-1.829 1.57L10 15.771l-3.398 1.788c-1.024.52-2.1-.413-1.83-1.57l.948-4.086-3.106-2.9c-.889-.818-.369-2.285.83-2.378l4.168-.325 1.286-3.968z" />
                          </svg>
                        ))}
                      </div>
                    </div> */}
                    <div className="mt-4 flex justify-between">
                      <button
                        className="bg-yellow-500 text-white py-2 px-4 rounded-lg"
                        onClick={() =>
                          navigate("/create-training-course", {
                            state: {
                              id: course.id,
                              name: course.name,
                              description: course.description,
                              image: `https://66-dementia.b-cdn.net/${env}/courses/images/${
                                course.id
                              }?${new Date().getTime()}`,
                            },
                          })
                        }
                      >
                        Update
                      </button>
                      <button
                        className="bg-blue-500 text-white py-2 px-4 rounded-lg"
                        onClick={() => openModal(null, course)}
                        // onClick={() => archive(course)}
                      >
                        Archive
                      </button>
                      <button
                        className="bg-red-500 text-white py-2 px-4 rounded-lg"
                        onClick={() => openModal(course.id, null)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          {/* Modal */}
          {isModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
                <h2 className="text-xl font-bold mb-4">
                  Confirm {courseId ? "Delete" : "Archive"}
                </h2>
                <p className="text-gray-600 mb-6">
                  {courseId
                    ? "Are you sure you want to delete this item?"
                    : "Are you sure you want to Archive this item?"}
                </p>
                <div className="flex justify-end space-x-4">
                  <button
                    className="bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-600"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-red-600 text-white py-2 px-4 rounded-lg hover:bg-red-700"
                    onClick={() => {
                      courseId
                        ? confirmDelete(courseId)
                        : archive(archiveCourse);
                    }}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </main>

      <FooterComponent path="/homescreen" />
    </div>
  );
};

export default TrainingCoursesPage;
