import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import training from "../../images/activetraining/1.png";
import specialisttraining from "../../images/activetraining/2.png";
import advancedtraining from "../../images/activetraining/3.png";
import HeaderComponent from "../admin/shared/HeaderComponent";
import Header from "./Header";

// const courses = [
//   {
//     title: "6D Training for Teams",
//     image: training,
//     description: [
//       "What is Dementia?",
//       "Looking at all the dimensions that are important to understand the person",
//       "Seeing the perspective of the person with dementia and those who offer care and support. What do we do when these are different?",
//       "Case studies",
//       "How best to record behaviour to facilitate understanding",
//       "The challenges experienced by staff and how to address these challenges",
//       "Supporting carers and care staff",
//     ],
//     rating: 5,
//     plays: 312,
//   },
//   {
//     title: "6D Specialist Team Training",
//     image: specialisttraining,
//     description: [
//       "Implementing 6D Dementia within your team",
//       "The processes that need to be in place to ensure good outcomes",
//       "How to engage staff who will implement recommendations",
//       "How to write recommendations to ensure that everyone who supports the person with dementia knows what to do to reduce potential distress",
//     ],
//     rating: 5,
//     plays: 198,
//   },
//   {
//     title: "6D Advanced Team Training",
//     image: advancedtraining,
//     description: [
//       "Bespoke support sessions",
//       "Challenges in implementation of the 6D Dementia Training program",
//       "Problem solve challenges",
//       "Implementation feedback",
//       "Supporting other team members",
//       "Complex dementia presentations",
//     ],
//     rating: 4,
//     plays: 187,
//   },
// ];

const CourseCard = ({
  id,
  course_id,
  course_name,
  course_description,
  percent_complete,
  navigate,
}) => (
  <div className="bg-white p-4 rounded-lg shadow-lg">
    <img
      src={`https://66-dementia.b-cdn.net/test/courses/images/${course_id}`}
      alt={course_name}
      className="w-full h-56 object-cover rounded-lg mb-4"
    />
    <div className="h-[375px] flex flex-col justify-between">
      <div className="">
        <div className="h-1/5">
          <h2 className="text-xl font-semibold mb-2">{course_name}</h2>
        </div>
        <div className="flex flex-col items-center">
          <div className="w-full bg-gray-300 rounded-full h-6 mb-2">
            <div
              className="bg-[#0089ad] h-6 rounded-full"
              style={{ width: `${percent_complete}%` }}
            ></div>
          </div>
          <p className="text-gray-700 mb-4">
            Completion Rate: {Math.round(percent_complete)}%
          </p>
        </div>
        <ul className="text-gray-700 text-sm mb-4">
          {course_description
            .split(".")
            .filter((item) => item !== "")
            .map((item, index) => (
              <li key={index} className="mb-1">
                * {item}
              </li>
            ))}
        </ul>
      </div>
      <div>
        {/* <div className=""> */}
        {/* <div className="flex items-center">
            {[...Array(5)].map((_, i) => (
              <svg
                key={i}
                className="w-4 h-4 text-blue-900"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 15l-5.878 3.09 1.123-6.545L.488 6.909l6.561-.953L10 0l2.951 5.956 6.561.953-4.757 4.636 1.123 6.545z" />
              </svg>
            ))}
          </div> */}
        <button
          className="bg-blue-900 mx-[35%] text-white px-10 py-2 rounded-3xl shadow-lg hover:bg-blue-700"
          onClick={() => {
            navigate("/coursedetails", {
              state: {
                courseName: course_name,
                userCourseId: id,
                courseId: course_id,
              },
            });
          }}
        >
          View
        </button>
        {/* </div> */}
        {/* <div className="text-[#0075d4] text-sm mt-2">Plays: 100</div> */}
      </div>
    </div>
  </div>
);

const ActiveTrainingCourses = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  return (
    <div className="min-h-screen bg-white ">
      <div className="container mx-auto px-4">
        <HeaderComponent />
        <h2 className="text-2xl font-semibold text-gray-700 mb-6">
          Active Training Courses
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-20">
          {state.courses.map((course, index) => (
            <CourseCard key={index} {...course} navigate={navigate} />
          ))}
        </div>
        <div className="mt-8 mb-4 flex justify-center">
          <button
            className="bg-blue-900 text-white px-10 py-2 rounded-3xl shadow-lg hover:bg-blue-700"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default ActiveTrainingCourses;
