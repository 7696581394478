import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateAdminComponent = () => {
  const auth = JSON.parse(sessionStorage.getItem("userdetails"));
  if (!auth || !auth.roles || !auth.roles.includes("Administrator")) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};

export default PrivateAdminComponent;
