import React, { useState } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { createLocation } from "../../apiFunction/ApiFunctions";
import FooterComponent from "../admin/shared/FooterComponent";
import HeaderComponent from "../admin/shared/HeaderComponent";

const CreateTeam = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [id, setId] = useState(state ? state.id : null);
  const [name, setName] = useState(state ? state.name : "");
  const [description, setDescription] = useState(
    state ? state.description : ""
  );
  const [address, setAddress] = useState(state ? state.address : "");
  const [phone, setPhone] = useState(state ? state.phone : "");
  // const [username, setUsername] = useState("");
  const [image, setImage] = useState(state ? state.image : null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
        console.log(reader.result.split(",")[1]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
    const userOrganisationData = JSON.parse(
      sessionStorage.getItem("organisationDetails")
    )[0];
    // Prepare the form data
    const team = {
      id: state ? state.id : null,
      organisation_id: userOrganisationData.id,
      name: name,
      address: address,
      phone_number: phone,
      // email_address: username,
      description: description,
      image: image
        ? image.includes("https://")
          ? ""
          : image.split(",")[1]
        : null,
    };

    console.log("location", team);
    createLocation(team)
      .then(async (response) => {
        // console.log("location", data);
        setIsLoading(false);

        if (response.ok) {
          const responseData = await response.json(); // Parse the response data as JSON
          console.log("location", responseData);

          navigate(
            "/teams"
            // , {
            //   state: {
            //     locations: [...state.locations, responseData],
            //   },
            // }
          );
        } else {
          // const errorText = await response.text();
          // console.error("Failed to create user:", errorText);
          setError("Failed to create location");
        }
        // navigate("/organisationdashboard")
      })
      .catch((error) => {
        console.error("Error creating location:", error);
      });
  };

  return (
    <div className="min-h-screen bg-white flex flex-col">
      <HeaderComponent />
      <div className="container mx-auto">
        <h1 className="text-3xl font-medium text-customBlue mb-8 ml-[-2%]">
          {state ? "Update Location" : "Create Location"}
        </h1>
        <form
          className="bg-white shadow-md rounded-lg p-8 mb-12"
          onSubmit={handleSubmit}
        >
          <div className="w-1/3 flex justify-between items-center">
            <div className="mb-4 border-2">
              {image ? (
                <img
                  src={image}
                  alt="Uploaded"
                  className="w-40 h-28  object-cover"
                />
              ) : (
                <div className="w-40 h-28  bg-gray-200 flex items-center justify-center">
                  <span className="text-gray-500">Upload Picture</span>
                </div>
              )}
            </div>
            <label
              htmlFor="file-upload"
              className="bg-customBlue text-white px-4 py-2 rounded-lg cursor-pointer"
            >
              Upload Location Image
            </label>
            <input
              id="file-upload"
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              className="hidden"
            />
          </div>
          <div className="">
            <div className="mb-4">
              <input
                type="text"
                value={name}
                placeholder="Enter location name"
                onChange={(e) => setName(e.target.value)}
                className="w-full border p-2 rounded mt-1"
                required
              />
            </div>

            <div className="mb-4">
              <textarea
                type="textarea"
                value={description}
                placeholder="Location description"
                onChange={(e) => setDescription(e.target.value)}
                className="w-full border p-2 rounded mt-1"
                required
              />
            </div>

            <div className="mb-4">
              <input
                type="text"
                value={address}
                placeholder="Enter location address"
                onChange={(e) => setAddress(e.target.value)}
                className="w-full border p-2 rounded mt-1"
                required
              />
            </div>

            <div className="mb-4">
              <input
                type="text"
                value={phone}
                placeholder="Enter telephone number"
                onChange={(e) => setPhone(e.target.value)}
                className="w-full border p-2 rounded mt-1"
              />
            </div>
            {/* <div className="mb-4">
              <input
                type="text"
                value={username}
                placeholder="Enter email address"
                onChange={(e) => setUsername(e.target.value)}
                className="w-full border p-2 rounded mt-1"
              />
            </div> */}

            {/* Map */}

            {/* <div className="mt-6 w-1/3 mx-auto">
              <h2 className="text-xl font-semibold text-center mb-4">Map</h2>
              <div className="w-full h-64 bg-gray-200 rounded-lg flex items-center justify-center">
                <iframe
                  src="https://www.google.com/maps/embed?..." // Replace with the actual map embed link
                  width="100%"
                  height="100%"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  className="rounded-lg"
                  title="Map"
                ></iframe>
              </div>
              <p className="text-center mt-4 text-gray-600">
                Drag and drop the marker to pinpoint the exact location
              </p>
            </div> */}

            <div className="flex justify-center">
              <button
                type="submit"
                className={`bg-blue-950 text-white text-xl py-2 px-8 rounded-lg mt-4`}
              >
                {!isLoading && (state ? "Update" : "Create")}
                {isLoading && (
                  <div role="status" className="ml-[]">
                    <svg
                      aria-hidden="true"
                      className="h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </button>
            </div>
            {error && (
              <div
                className="bg-red-100 border border-red-400 text-red-700 px-2 py-3 rounded relative w-1/3 ml-[33.33%] text-center mt-2"
                role="alert"
              >
                <span className="block  sm:inline">Error : {error}</span>
              </div>
            )}
          </div>
        </form>
      </div>
      <FooterComponent path={-1} />
    </div>
  );
};

export default CreateTeam;
